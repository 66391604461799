<template>
  <div class="dialog">
    <div class="dialog-header">
      <div class="dialog-title">CREATE PLAYLIST</div>
    </div>

    <div class="dialog-content pb-0">
      <b-form
        class="playlist-form"
        @submit="createPlaylist">
        <b-form-group
          class="align-items-center"
          label="Name"
          label-for="name"
          label-cols="3">
          <b-form-input
            type="text"
            placeholder="Enter Playlist Name"
            autocomplete="off"
            size="sm"
            v-model.trim="$v.form.name.$model"
            @input="$v.form.name.$touch()"
            :state="!$v.form.name.$error"
          ></b-form-input>
          <b-form-invalid-feedback>
            <span v-if="!$v.form.name.required">
              Name is required.
            </span>
            <span v-if="!$v.form.name.uniqueName && $v.form.name.required">
              Name is already used.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          class="align-items-center"
          label="Premium"
          label-for="premium"
          label-cols="3">
          <toggle-button
            class="large-font mb-0"
            v-model="form.premium"
            :width="100"
            :height="35"
            :labels="{ checked : 'YES', unchecked : 'NO' }"
          />
        </b-form-group>

        <b-form-group
          class="align-items-center"
          label="Publish"
          label-for="publish"
          label-cols="3">
          <toggle-button
            class="large-font mb-0"
            v-model="form.publish"
            :width="100"
            :height="35"
            :labels="{ checked : 'YES', unchecked : 'NO' }"
          />
        </b-form-group>

        <b-form-group
          class="align-items-center"
          label="Thumbnail"
          label-for="thumbnail"
          label-cols="3">
          <b-form-file
            accept="image/jpeg, image/png"
            v-model="form.thumbnail"
            @change="$v.form.thumbnail.$touch()"
            :state="!$v.form.thumbnail.$error"
          ></b-form-file>
          <b-form-invalid-feedback :state="!$v.form.thumbnail.$error">
            <span v-if="!$v.form.thumbnail.required">
              Thumbnail is required.
            </span>
            <span v-if="!$v.form.thumbnail.validThumbnail &&
              $v.form.thumbnail.required">
              Invalid file type.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          class="align-items-center"
          label="Banner Display"
          label-for="bannerDisplay"
          label-cols="3">
          <toggle-button
            class="large-font mb-0"
            v-model="form.bannerDisplay"
            :width="100"
            :height="35"
            :labels="{ checked : 'YES', unchecked : 'NO' }"
          />
        </b-form-group>

        <b-form-group
          class="align-items-center"
          label="Banner"
          label-for="banner"
          label-cols="3"
          v-if="form.bannerDisplay">
          <b-form-file
            accept="image/jpeg, image/png"
            v-model="form.banner"
            @change="$v.form.banner.$touch()"
            :state="!$v.form.banner.$error"
          ></b-form-file>
          <b-form-invalid-feedback :state="!$v.form.banner.$error">
            <span v-if="!$v.form.banner.required">
              Banner is required.
            </span>
            <span v-if="!$v.form.banner.validBanner && $v.form.banner.required">
              Invalid file type.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="d-flex justify-content-end">
          <b-button
            type="submit"
            variant="outline-success"
            :disabled="$v.form.$invalid">
            Submit
          </b-button>
          <b-button
            class="ml-1"
            type="button"
            variant="outline-danger"
            @click="$emit('close')">
            Cancel
          </b-button>
        </div>
      </b-form>
    </div>

    <AppLoaderAdmin :isLoading="loading" />
  </div>
</template>

<script>
  import _ from 'lodash';
  import { required } from 'vuelidate/lib/validators';

  export default {
    data() {
      return {
        loading : false,
        form    : {
          name          : null,
          premium       : false,
          publish       : false,
          thumbnail     : null,
          banner        : null,
          bannerDisplay : true,
        },
        validBanner    : false,
        validThumbnail : false,
        uniqueName     : true,
        progress       : 0,
      }
    },
    components : {
      'AppLoaderAdmin' :
        () => import('../../../components/layout/AppLoaderAdmin'),
    },
    watch : {
      'form.name'(val) {
        this.uniqueName = true;
        if (val)
          this.checkPlaylistName();
      },
      'form.thumbnail'(val) {
        this.validThumbnail = true;
        if (val && !val.type.startsWith("image/")) {
          this.$nextTick(() => {
            this.validThumbnail = false;
          });
        }
      },
      'form.banner'(val) {
        this.validBanner = true;
        if (val && !val.type.startsWith("image/")) {
          this.$nextTick(() => {
            this.validBanner = false;
          });
        }
      },
    },
    methods : {

      /**
       * Create Playlist
       */
      async createPlaylist(e) {
        e.preventDefault();
        this.loading = true;
        const formData = new FormData();
        formData.append('name', this.form.name);
        formData.append('premium', (this.form.premium) ? 1 : 0);
        formData.append('published',  (this.form.publish) ? 1 : 0);
        formData.append('displayBanner', (this.form.bannerDisplay) ? 1 : 0);
        formData.append('thumbnail', this.form.thumbnail);
        formData.append('banner', this.form.banner);

        try {
          const res = await this.$http.post('api/playlist', formData, {
            onUploadProgress : event =>
              this.progress = Math.round(event.loaded * 100 / event.total),
          });

          if (res) {
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success',
              text  : 'Created playlist successfully!',
            });
            this.$parent.$emit('reload-table');
            this.$emit('close');
            this.loading = false;
          }
        } catch(err) {
          this.loading = false;
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }
      },

      /**
       * Check Playlist Name
       */
      checkPlaylistName : _.debounce(function() {
        this.$http.get('api/playlist/check', {
          params : {
            name : this.form.name,
          },
        }).then(response => {
          this.uniqueName = (response.data) ? false : true;
        }).catch(() => {});
      }, 500),
    },
    validations() {
      return {
        form : {
          name : {
            required,
            uniqueName() {
              return this.uniqueName;
            },
          },
          premium   : { required },
          publish   : { required },
          thumbnail : {
            required,
            validThumbnail() {
              return this.validThumbnail;
            },
          },
          banner : (this.form.bannerDisplay === true) ? {
            required,
            validBanner() {
              return this.validBanner;
            },
          } : {},
          bannerDisplay : { required },
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/settings";

  .form-control {
    height: auto !important;
    font-size: auto !important;
  }

  .croppa-container {
    border: 1px dashed #068bcd;
  }
</style>